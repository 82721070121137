
import {computed, defineComponent, ref, watch} from "vue";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  isPlatform
} from "@ionic/vue";
import router from "@/app/router";
import {formatDate} from "@/domain/helper/date.helper";
import {useStore} from "vuex";
import {EntryDelivery} from "@/domain/model/delivery/delivery.model";

export default defineComponent({
  name: "EntryDeliveryListComponent",
  components: {
    // IonText,
    IonItem,
    IonLabel,
    IonRow,
    IonCol,
    IonButton,
    IonGrid,
  },
  props: {
    delivery: null,
  },
  setup(prop: any) {
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));
    const deliveryStampToDate = computed(() => {
      return formatDate(prop.delivery.date, "DD-MM-YYYY");
    });

    const deliveryStampToDateHour = computed(() => {
      return formatDate(prop.delivery.date, "HH:mm");
    });


    const goDetailsComponent = async () => {
      await router.push({
        name: 'detailsEntryDelivery',
        params: {id: prop.delivery.id}
      });
    }
    return {deliveryStampToDate, goDetailsComponent, deliveryStampToDateHour, isTablet};
  }
})
