
import {computed, defineComponent, h, ref, watch} from "vue";
import {
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonItemDivider,
  IonList,
  IonPage,
  IonRow, IonSearchbar, isPlatform, onIonViewWillEnter
} from "@ionic/vue";
import {useStore} from "vuex";
import EntryDeliveryListComponent from "@/app/view/delivery/list/EntryDeliveryListItemComponent.vue";
import _, {map} from "lodash";
import {useDeliveryApi} from "@/domain/service/api/delivery.api";
import {useMaterialApi} from "@/domain/service/api/material.api";
import {EntryDelivery} from "@/domain/model/delivery/delivery.model";
import {formatDate} from "@/domain/helper/date.helper";
import {useStockApi} from "@/domain/service/api/stock.api";
import {NButton, NDataTable, NSpace} from "naive-ui";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "EntryDeliveryRecord",
  components: {
    IonCard,
    IonPage,
    IonContent,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItemDivider,
    IonSearchbar,
    EntryDeliveryListComponent,
    NSpace,
    NDataTable,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const searchWord = ref('');
    const data = ref<any>([]);
    const searchEntryDelivery = (entryDelivery: EntryDelivery, word: string): boolean => formatDate(entryDelivery.date,"DD-MM-YYYY").includes(word.toLowerCase()) ||
    entryDelivery.indexPosition.includes(word);

    const filterEntryDeliveries = (entryDeliveries: EntryDelivery[], word: string): EntryDelivery[] => _.filter(entryDeliveries, (entryDelivery: EntryDelivery) => searchEntryDelivery(entryDelivery, word));
    const sortEntryDeliveries = (entryDeliveries: EntryDelivery[]): EntryDelivery[] => _.orderBy(entryDeliveries, ['date'], ['desc']);

    const entryDeliveries = computed<EntryDelivery[]>(() => sortEntryDeliveries(filterEntryDeliveries(store.getters.allEntryDeliveries, searchWord.value)));

    const isEmpty = computed(() => store.getters.allEntryDeliveries?.length === 0);
    const isTablet = computed<boolean>(() => !isPlatform('desktop'));

    const deliveryStampToDate = (date: number) => formatDate(date, "DD-MM-YYYY");

    const deliveryStampToDateHour = (date: number) => formatDate(date, "HH:mm");


    const goDeliveryDetails = async (id: string) => {
      await router.push({
        name: 'detailsEntryDelivery',
        params: {id: id}
      });
    }
    const replaceData = () => {
      data.value =
          map(entryDeliveries.value, (entryDeliveries: EntryDelivery) => {
            return {
              id: entryDeliveries.id,
              date: deliveryStampToDate(entryDeliveries.date),
              time: deliveryStampToDateHour(entryDeliveries.date),
              number: entryDeliveries.indexPosition,
            };
          });
    };


    watch(entryDeliveries, () => replaceData(), {immediate: true});

    onIonViewWillEnter(() => {
      useMaterialApi().fetchAll();
      useDeliveryApi().fetchAll();
      useStockApi().fetchAll();
    });
    return {
      data,
      columns: [
        {
          title: "Fecha",
          key: "date",
          width: '100px',
          sorter: 'default'
        },
        {
          title: "Hora",
          width: '100px',
          key: "time",
          sorter: 'default'
        },
        {
          title: "Número de albarán",
          key: "number",
          width: '100px',
          sorter: 'default'
        },
        {
          title: 'Acciones',
          width: '100px',
          key: 'actions',
          render(row: any): any {
            return h(
                NButton,
                {
                  size: 'small',
                  onClick: () => goDeliveryDetails(row.id),
                },
                {default: () => 'Detalles'},
            );
          },
        },
      ],
      searchWord,
      entryDeliveries,
      isEmpty,
      isTablet,
    };
  }
})
